<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <div class="flex flex-col">
          <jublia-p class="w-full mt-6 mb-10 text">
            {{ $t('error-form.text') }}
          </jublia-p>
          <router-link to="step-calculator"
            class="no-underline "
            style="margin-top: 20rem;"
          >
            <jublia-button class="uppercase">
              {{ $t('error-form.button') }}
            </jublia-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JubliaHeader from '@/components/header/jublia-header'
import JubliaP from '@/components/text/jublia-p'
import JubliaButton from '@/components/button/jublia-button'

export default {
  components: {
  'jublia-button':JubliaButton,
 //JubliaH1,
 'jublia-p':JubliaP,
  'jublia-header':JubliaHeader,

     }
}
</script>
<style scoped>
.container {
  min-height: 39rem;
  @media all and (min-width: 768px) {
   
   /*min-height: 35rem;*/
  }
}
</style>

